import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  SummaryTitle,
  TabSwitcher,
  Button,
  ImagePreview,
  ScanCodeConsultButton,
  PageBottomCard,
  TestimonyCard,
  MoreResources,
} from 'components/index'
import Carousel from 'components/Carousel'
import { ButtonGroup } from 'components/Base/Button'
import GreenCard from 'components/Analysis/GreenCard'
import ShowCard from 'components/engines/ShowCard/index'
import SigningPlate from 'components/Solutions/SigningPlate'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

import * as styles from './index.module.less'
import JoeEnginesPreview from './img/JoeEnginesPreview.png'
import Notebook from './img/notebook_icon.png'

const Joe = (props) => {
  const { data } = props
  const {
    allJoeYaml: { nodes },
  } = data
  const { banner, part2, part3, part4, part5, part6, part7 } = nodes[0] || {}

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="神策客户旅程优化引擎 | 用户体验优化平台"
        description="通过客户旅程分析了解用户行为和需求，自动化编排客户旅程，为用户提供个性化内容及服务，从而提升客户满意度和忠诚度，帮助企业实现业务增长和市场竞争力提升。"
        keywords="用户旅程,CJO客户旅程编排,用户体验, 客户旅程地图,客户旅程分析,优化"
      />

      <div className={styles.JOERoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={banner?.title}
            desc={banner?.desc}
            buttons={banner?.buttons}
            enTitle={banner?.enTitle}
            className={classnames(styles.banner)}
            h1tag={true}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            enTitleClassName={styles.enTitle}
          />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle
            title="客户全生命周期旅程编排"
            desc="通过四步工作流构建客户旅程，实现全生命周期的交互体验管理"
          />
          <div className="flex justify-center md:mt-[73px] pt-[2.6rem] lg:pt-0 mt-[2.6rem] md:px-0 px-[2rem]">
            <ShowCard stepArr={part2.stepArr} buttons={part2.buttons} />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle
            title="客户旅程优化引擎的全景图"
            desc="包括四大服务能力和一系列营销应用，赋能客户全生命周期旅程编排"
          />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview imageUrl={JoeEnginesPreview} alt="客户旅程优化引擎应用场景" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            <Button href="https://www.sensorsdata.cn/form/parade.html" target="_blank" btnType="primary">
              预约演示
            </Button>
            <ScanCodeConsultButton
              className="lg:mx-[20px] ml-[16px] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/wxcode.png"
              size="small"
              target="_blank"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              ghost
              buttonType="primary"
            />
          </ButtonGroup>
        </div>
        <div className={classnames(styles.wWarrper, 'lg:!pb-0 !pb-[30px]')}>
          <SummaryTitle
            title="客户旅程优化引擎的四大服务能力"
            desc="强调 API First，基于平台化思路打造四大服务能力，赋能企业和合作伙伴"
          />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              longTitle
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
            />
          </div>
        </div>
        <div className={classnames(styles.bWarrper, styles.notebook)}>
          <div className={classnames('lg:w-[1200px] mx-auto lg:flex ')}>
            <div>
              <img
                className="lg:w-[418px] lg:h-[228px]"
                src={Notebook}
                alt="神策 JOE + 神策 CDP，打造实时、个性化之旅"
              />
            </div>
            <div className="lg:ml-[70px] lg:mt-[40px] flex-1 ">
              <h4 className="mt-[26px] lg:mt-0 text-[16px] w-[172px] mx-auto lg:w-auto lg:text-[18px] font-medium text-[31f2d3d] leading-[22px] lg:leading-[25px]">
                {isMb ? '神策 JOE + 神策 CDP打造实时、个性化之旅' : '神策 JOE + 神策 CDP，打造实时、个性化之旅'}
              </h4>
              <p className="px-[16px] lg:px-0 text-center lg:text-left mt-[10px] lg:mt-[6px] text-[14px] text-[#475669] leading-[20px]">
                神策客户数据引擎（CDP）作为数据底座，为神策客户旅程优化引擎（JOE）提供强大、实时、自助式的数据应用能力，赋能个性化客户旅程编排，激活数据价值。
              </p>
              <p className="mt-[30px] text-center lg:text-left lg:mt-[60px]">
                <Button href="https://www.sensorsdata.cn/engines/CDP.html" target="_blank" btnType="primary">
                  了解更多
                </Button>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle
            title="为什么选择神策？"
            desc="神策客户旅程优化引擎拥有实时、灵活配置、高并发、开放性四大必备能力，助力企业更高效地经营客户"
          />
          <div className="lg:mt-[60px] mt-[26px] lg:w-[1200px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part4?.dataSource?.map((item) => {
              return <GreenCard className="mt-[14px] lg:mt-0" key={item?.title} dataSource={item} />
            })}
          </div>
          <ButtonGroup className="lg:mt-[60px] mt-[30px] text-center">
            <Button href="https://www.sensorsdata.cn/form/parade.html" target="_blank" btnType="primary">
              预约演示
            </Button>
            <ScanCodeConsultButton
              className="ml-[20px]"
              qrcode="https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              btnType="primary"
              ghost
            />
          </ButtonGroup>
        </div>
        {/* 赋能跨境电商平台的数字化经营 */}
        <div className={styles.bWarrper}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part6} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <Carousel img={part6?.icons?.publicURL || part6?.icons} className={styles.carousel} showMask iconContain />
          </div>
        </div>
        {/* 更多干货 */}
        <div className={styles.wWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[2rem] pl-[20px] pr-[20px]">
            <MoreResources data={part7?.dataSource} />
          </div>
        </div>
        <div className={styles.bWarrper} style={{ paddingBottom: '0px' }}>
          <SummaryTitle title="其他平台引擎" />
          <SigningPlate
            className="lg:block "
            dataSource={part5}
            linkIconClassName={styles.linkIconClassName}
            enTitle={part5?.enTitle}
          />
          <div className="lg:mt-[40px] mt-[2.6rem]">
            <PageBottomCard
              title="开启大数据分析与营销科技之旅！"
              desc="立即注册，和神策数据专家一起探讨数字化！"
              leftButtonText="体验 Demo"
              leftButtonHref="/demo/demo.html"
              rightButtonText="预约演示"
              rightButtonHref="/form/parade.html"
              type="newBg"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allJoeYaml(filter: { banner: { title: { eq: "客户旅程优化引擎" } } }) {
      nodes {
        banner {
          title
          enTitle
          desc
          buttons {
            text
            href
            btnType
            ghost
          }
        }
        part2 {
          stepArr {
            title
            desc
            img {
              publicURL
            }
            mbImg {
              publicURL
            }
          }
          buttons {
            text
            href
          }
        }
        part3 {
          data {
            title
            reverse
            showIcon
            sortTitle
            content {
              img {
                publicURL
              }
              alt
              introduction1
              buttons {
                btnType
                href
                text
                target
              }
              introductionList {
                content
              }
            }
          }
        }
        part4 {
          dataSource {
            title
            desc
            content
          }
        }
        part5 {
          left {
            title
            bg {
              publicURL
            }
            desc
            btn_text
            btn_link
          }
          right {
            title
            bg {
              publicURL
            }
            desc
            btn_text
            btn_link
          }
          enTitle
        }
        part6 {
          title
          text
          signature
          imgUrl {
            publicURL
          }
          imgAlt
          icons {
            publicURL
          }
        }
        part7 {
          title
          dataSource {
            img {
              publicURL
            }
            title
            tip
            url
            text
          }
        }
      }
    }
  }
`

export default Joe
